.Header {
	width: 100%;
	position: sticky;
	flex-direction: row;
	flex-grow: 0;
	display: flex;
	height: 3.6rem;
	justify-content: space-between;
	align-items: center;
	&__changePassword {
		&__row {
			justify-content: space-between;
			display: block;
			padding: 0.5rem 0rem;
			&__button {
				padding: 0.3rem 0rem;
				text-align: center;
			}
		}
		&__label {
			display: flex;
			align-items: center;
		}
	}
	&__sizeColor {
		font-size: 15px;
		color: #8c8c8c;
	}

	&__alertText {
		color: #8c8c8c;
		font-size: 10px;
		margin-left: 10px;
		justify-content: space-between;
		display: block;
		max-width: 350px;
	}

	&__leftSide {
		flex: 40%;
		overflow: hidden;
		order: 1;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		padding: 0.5rem 0rem;
		img {
			width: 1rem;
			height: 1rem;
		}
		.Mui-disabled {
			-webkit-text-fill-color: black !important;
		}
	}
	&__rightSide {
		flex: 20%;
		overflow: hidden;
		order: 2;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: center;
		// margin-left: 11rem;
		&__section {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			height: var(--header-height);
		}
		&__profile {
			display: flex;
			align-items: center;
			cursor: pointer;
			&__icon {
				width: 38px !important;
				height: 38px !important;
			}
			&__name {
				color: #55708d;
				margin-left: 10px;
				max-width: 150px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: hidden;
			}
		}
		&__icon {
			margin-left: 10px;
			color: var(--primary-theme) !important;
		}
	}
}

.changePasswordStyle .MuiPaper-root {
	border-radius: 20px;
	box-shadow: #6f54f645 0px 10px 24px;
	top: 43px !important;
}

.changePasswordStyle .MuiMenu-paper .MuiList-root .MuiMenuItem-root {
	display: flex !important;
}

.BreadCrumbs__typography .MuiLink-underlineNone {
	cursor: default;
}
.BreadCrumbs__typography .MuiBreadcrumbs-separator {
	color: var(--primary-theme);
	margin: 0.25rem;
}
.BreadCrumbs__typography .MuiTypography-root,
.BreadCrumbs__typography .MuiBreadcrumbs-li {
	font-size: 15px;
	font-style: italic;
}
@media screen and (max-width: 1366px) {
	.BreadCrumbs__typography .MuiTypography-root,
	.BreadCrumbs__typography .MuiBreadcrumbs-li {
		font-size: 12px;
	}
}
