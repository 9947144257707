.dropdown {
	&__menu {
		&__paper {
			border-radius: 20px !important;
			max-height: 25% !important;
		}
	}

	svg {
		color: var(--primary-theme) !important;
	}

	&__textExceed {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.MuiInputLabel-root {
		// font-size: 0.9rem;
		// top: 50%;
		// background-color: white;
		// transform: translate(16%, -50%);
	}

	.MuiFormLabel-filled {
		// top: 0;
		// font-size: 0.7rem !important;
	}
}

.dropdownDark {
	&__menu {
		&__paper {
			border-radius: 20px !important;
			max-height: 25% !important;
		}
	}

	svg {
		color: var(--primary-theme) !important;
	}

	&__textExceed {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.MuiInputLabel-root {
		// font-size: 0.9rem;
		// top: 50%;
		// background-color: #24282f;
		// background-color: inherit;
		// transform: translate(16%, -50%);
	}

	.MuiFormLabel-filled {
		// top: 0;
		// font-size: 0.7rem !important;
	}

	// .MuiFormLabel-root-MuiInputLabel-root {
	// 	top: -6px;
	// }
}

.dropdowninDataTable {
	min-width: 5rem;
	&__leftMargin {
		margin-left: 5rem;
		@media (max-width: 1366px) {
			margin-left: 2rem;
		}
		@media (max-width: 1440px) {
			margin-left: 2rem;
		}
	}
	.dropdownDark {
		&__menu {
			&__paper {
				border-radius: 20px !important;
				max-height: 25% !important;
			}
		}

		svg {
			color: white !important;
		}
	}
}

.dropdown .MuiOutlinedInput-root {
	border-radius: 30px !important;
	// height: var(--dropdown--height);
}

.dropdownDark .MuiOutlinedInput-root {
	border-radius: 30px !important;
	// height: var(--dropdown--height);
	// height: 45px;
}

.dropdown .MuiPaper-root {
	box-shadow: 0px 2px 2px -1px rgb(0 0 0 / 20%),
		0px 6px 8px 1px rgb(0 0 0 / 14%), 0px 2px 10px 0px rgb(0 0 0 / 12%) !important;
}

.dropdownDark .MuiPaper-root {
	box-shadow: 0px 2px 2px -1px rgb(0 0 0 / 20%),
		0px 6px 8px 1px rgb(0 0 0 / 14%), 0px 2px 10px 0px rgb(0 0 0 / 12%) !important;
}

// .Mui-focused {
// 	top: 0;
// }
