// ========= styles for drag and drop zone in custom waterfall
.ChannelManager {
	.container {
		display: flex;
		touch-action: none;
		width: 100%;
		// min-height: 80rem;
		min-height: 40rem;
		margin: 1rem auto;
		flex-direction: column;
	}

	.dropzone {
		flex: 1;
		// height: 40rem;
		// height: 100% !important;
		// border: 2px solid red;
		border-radius: 1rem;
		display: flex;
		flex-direction: column;
		margin-bottom: 1rem;

		.dropzoneGridContainer {
			cursor: auto !important;
			z-index: 999 !important;
		}

		.dropZoneGridItem {
			height: auto !important;
			width: 17rem !important;
		}
	}

	.dropzone.left {
		margin-right: 10px;
	}

	.grid-item {
		width: 100%;
		height: 100%;
		box-sizing: border-box;
		padding: 0.5em;
	}

	.draggableCards {
		.ChannelManager__body__card {
			width: 12rem;
		}
	}

	.grid-item-content {
		width: 100%;
		width: 14rem;
		height: 100%;
		box-sizing: border-box;
		// background: #08e;
		display: flex;
		justify-content: center;
		color: white;
		align-items: center;
		border-radius: 0.5em;
	}
}

// =========
.ChannelManager {
	width: -webkit-fill-available;
	height: 100%;
	width: 100%;
	// overflow: auto;
	overflow-y: hidden;
	scroll-behavior: smooth !important;

	.channelManagerHeadingButtons {
		font-size: 1rem !important;

		// svg > g > path {
		// 	fill: white;
		// }
	}

	.editCustomWaterfallIcon {
		background: none !important;
		box-shadow: none !important;
	}

	&__marginForFixedHeading {
		margin-top: 7rem;
		// overflow-y: auto;
		height: calc(100% - 7rem);
	}

	&__donutSync {
		// display: flex;
		// align-items: center;
		// justify-content: center;
		// position: fixed;
		// margin-top: 1rem;
		text-align: center;
		position: absolute;
		top: 7rem;
		left: 7rem;
		z-index: 999;
	}

	&__tabs {
		// background-color: var(--page-bgColor);
		margin: 1rem 2rem 0rem;

		.MuiButtonBase-root {
			min-height: 50px;
		}

		.infoIcon {
			top: 2px;
			position: absolute;
			right: 17px;
		}
	}

	&__flexContent {
		display: flex;
		align-items: center;
	}

	&__ruleNumberandIcon {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&__rules {
		font-weight: 600 !important;

		&__underline {
			height: 0.2rem;
			width: 2rem;
		}
	}

	&__info {
		color: #bfbfbf;
		letter-spacing: 0.1px;
		font-weight: normal;
	}

	&__rulesWithValue {
		color: var(--primary-theme);
		text-decoration: underline;
		cursor: pointer;
	}

	&__addIcon {
		border: 1px solid var(--primary-theme);
		border-radius: 25px;
		color: var(--primary-theme);
	}

	&__body {
		margin: 0rem 2rem;
		// height: calc(100% - 8.5rem);
		// min-height: 40rem;
		height: inherit;
		// background-color: white;
		border-radius: 0px 20px 20px 20px;
		padding: 0.8rem;
		padding-top: 0;
		overflow: hidden;
		overflow-y: scroll;
		margin-bottom: 4rem;
		scroll-behavior: smooth !important;

		&__heading {
			display: flex;
			align-items: center;
			justify-content: space-between;
			min-height: 56px;
		}

		&__list {
			display: grid;
			display: flex;
			flex-wrap: wrap;
			grid-template-columns: repeat(auto-fit, minmax(42rem, 1fr));
			background-color: #f7f7f7;
			// margin: 3rem;
		}

		&__card {
			// display: grid;
			grid-template-columns: 1fr 1fr;

			width: 14rem;
			margin: 1.4rem;
			box-shadow: 0 0.7rem 1.2rem rgba(0, 0, 0, 0.2);
			cursor: pointer;
			// transition: all 0.3s;
			// box-shadow: 0px 25px 30px #0000001a;
			border-radius: 20px;
			// margin: 0.8rem 1.1rem 0rem 0rem;
			padding: 1.2rem 0.6rem 1rem 1.4rem;
			// transition: transform ease-in-out;

			&:hover {
				// cursor: move;
				transform: scale(1.05);
				transition: all 0.2s ease-in-out;

				// .rankIcon {
				// 	display: none !important;
				// }

				.dragIcon {
					display: inherit !important;
				}
			}

			&__heading {
				display: flex;
				align-items: center;
				justify-content: space-between;
			}
		}

		&__card .dragging {
			cursor: move;
			// transform: scale(0.8);
		}

		// .drop {
		// 	/* Change the transform property to make the card look like it's dropping */
		// 	transform: scale(2);
		// 	transition: all 10s ease-in-out;
		// 	cursor: move;
		// 	border: 2px solid green($color: #000000);
		// }

		&__channelName {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			font-weight: 600 !important;
			letter-spacing: 0.08px;

			&__rankIcon {
			}
		}

		&__icons {
			width: 20px;
			height: 20px;
			margin: 0px 0px 0px 8px;
		}

		&__switch {
			float: right;
			display: inline-flex;
			align-items: center;
			justify-content: space-around;
		}
	}

	&__addChannel {
		&__channel {
			margin: 1rem 1rem;

			&__name {
				padding: 0rem 0.5rem;
				margin-bottom: 0.18rem;
				display: inline-flex;
				align-items: center;
			}

			&__deleteIcon {
				margin-left: 0.5rem;
				padding: 0.4rem;
			}

			&__add-rule {
				padding: 0.5rem;

				&__rule {
					overflow-y: scroll;
					height: calc(72vh - 3.8rem - 1rem - 2.4rem - 1.87rem - 0.5rem - 4rem);
					margin-top: 1rem;
				}
			}
		}

		&__dialogRoot {
			border-radius: 20px;
			overflow: inherit;
			min-width: 75vw;
			min-height: 60vh;
			max-height: 72vh;
			// overflow: hidden;
			// overflow-y: hidden !important;
			overflow-y: scroll;
			overflow-x: hidden;
		}

		@media screen and (max-width: 1024px) {
			&__channel {
				margin: 0.2rem -0.7rem;
			}

			&__dialogRoot {
				border-radius: 20px;
				overflow: inherit;
				min-width: 92vw;
				min-height: 60vh;
				max-height: 72vh;
				overflow: scroll;
				// overflow: hidden;
				// overflow-y: hidden !important;
			}
		}

		&__editDialog {
			border-radius: 20px;
			overflow: inherit;
			min-width: 500px;
			min-height: 40vh;
		}

		&__dialogContentRoot {
			margin: 0rem 1rem;
		}
	}

	&__addRule {
		&__heading {
			padding: 0.5rem 0rem;
		}

		&__content {
			width: inherit;
			padding: 0.5rem 1rem 0rem 1.5rem;
		}

		&__root {
			min-width: 70vw !important;
			min-height: 50vh;
			max-height: 80vh;
			border-radius: 30px !important;
			overflow: inherit !important;
			padding: 0.8rem 0.8rem;
		}

		&__closeBtn {
			position: absolute !important;
			padding: 0px !important;
			top: -2px !important;
			right: 0px !important;
			color: #000000 !important;
		}

		&__conditionButton {
			margin: 0rem 0.4rem 0 0rem !important;
			border-radius: 18px !important;
			border: 1px solid #bfbfbf;
			border-right-color: none !important;
			width: 38%;
			font-size: 0.9rem !important;
			height: 45px;
			font-weight: 600 !important;

			img {
				width: 1.1rem;
			}
		}

		&__rule {
			margin: 0rem 0rem 0.5rem;

			&__name {
				display: flex;
				align-items: center;
			}

			&__details {
				margin: 0.5rem 0rem;
				width: 100%;
				display: inline-flex;
				justify-content: space-between;
				align-items: center;
			}

			&__item {
				width: 20%;

				.MuiAutocomplete-root {
					width: 100% !important;
				}
			}

			&__seprator {
				width: inherit;
				content: '\00a0\00a0\00a0\00a0\00a0';
				text-align: center;
				overflow: hidden;
				margin: 0.2rem 1rem 0.5rem 0rem;
				font-size: 18px;
				color: var(--primary-theme);

				::after,
				::before {
					background-color: #bfbfbf;
					content: '';
					display: inline-block;
					height: 1px;
					position: relative;
					vertical-align: middle;
					width: 50%;
				}

				::before {
					margin-left: -40%;
					margin-right: 0.5rem;
				}

				::after {
					margin-right: -40%;
					margin-left: 0.5rem;
				}
			}
		}
	}

	&__darkClass {
		.highcharts-container .highcharts-legend-navigation {
			fill: #ffffff;
		}
	}
}

.ChannelManager__addRule__rule__item .MuiAutocomplete-popper,
.MuiAutocomplete-paper {
	border-radius: 20px !important;
}

.ChannelManager__addRule__rule__item
	.MuiAutocomplete-root
	.MuiInputLabel-shrink {
	// transform: translate(11px, -9px) scale(1) !important;
	// font-size: 0.9rem !important;
}
