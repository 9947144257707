// normal styles ===================================
@mixin collapse-sidebar-icon-style($left) {
	cursor: pointer;
	position: absolute;
	bottom: 0%;
	height: 40px;
	width: 40px;
	left: $left;
	border-radius: 50%;
	background-color: var(--primary-theme);
	box-shadow: 27px 21px 38px -17px rgba(232, 217, 255, 0.44);
	transition: all 0.2s ease-in-out;
	margin-bottom: 8px;
	img {
		&:hover {
			box-shadow: 27px 21px 38px -17px rgba(175, 162, 193, 0.44);
			background-color: var(--primary-theme);
			color: var(--sidebar-theme);
		}
	}
	&:hover {
		box-shadow: rgba(0, 0, 0, 0.246) 0px 4px 12px;
	}
}

@mixin collapse-sidebar-Editprofile-style($left) {
	cursor: pointer;
	position: absolute;
	bottom: 0%;
	width: 100%;
}

.Sidebar {
	.iconColor {
		color: var(--primary-theme);
	}
	.icon {
		@include collapse-sidebar-Editprofile-style(180px);
	}
	&__collapseButton.open {
		@include collapse-sidebar-icon-style(180px);
	}
	&__collapseButton.close {
		@include collapse-sidebar-icon-style(20px);
	}
	.Sidebar__listClosed {
		padding-bottom: 0px;
		padding-top: 0px;
	}
	.Sidebar__collapsebutton {
		&:hover {
			color: #fff;
		}
	}
	&__OpenMenuButton {
		margin-right: 28px !important;
		margin-top: 60px !important;
		svg {
			color: rgba(0, 0, 0, 0.257);
			position: absolute;
			top: 5px;
			border-radius: 50%;
			border: 0.5px solid rgba(0, 0, 0, 0.312);
		}
	}
	&:hover {
		.Sidebar__collapseButton {
			opacity: 1;
		}
	}
	&__appbar {
		box-shadow: none !important;
		overflow: hidden;
		float: left;
		.MuiToolbar-root {
			min-height: auto !important;
		}
	}
	&__appbarClosed {
		box-shadow: none !important;
		width: calc(100% - 55px) !important;
		overflow: hidden;
		float: left;
		.MuiToolbar-root {
			min-height: auto !important;
		}
	}
	&__MenuIcon {
		background-color: none;
	}
	&__logoContainer {
		justify-content: center;
		display: flex;
		width: -webkit-fill-available;
		max-width: 220px;
		align-items: center;
		height: 5.5rem;
	}
	&__logoContainerClosed {
		justify-content: center;
		display: flex;
		align-items: flex-end;
		width: -webkit-fill-available;
		height: 5.5rem;
		align-content: center;
		flex-wrap: wrap;
	}
	&__drawer {
		width: var(--sidebar-width);
		flex-shrink: 1;
	}
	&__lineSelector {
		position: absolute;
		height: 100%;
		left: 0px;
		border-right: 4px solid var(--primary-theme);
		border-radius: 0px 24px 24px 0px;
	}
	&__header {
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: var(--page-bg-color) !important;
		height: 152px !important;
		color: var(--primary-theme);
		font-weight: normal !important;
		letter-spacing: 0.19px;
	}
	&__list {
		padding: 0px !important;
	}
	&__divider {
		outline: none;
		height: 1px;
		margin-left: 0px;
		padding: 0 !important;
		width: 100%;
	}
	&__item {
		padding: 0.8rem 1rem !important;
		margin: 0.2rem 0rem !important;
		position: relative;
		cursor: pointer;
		background-color: inherit !important;
		&__clicked {
			background-color: green;
		}
		&__svg {
			&__lightModeHover {
				transform: scale(1.2);
				animation: glowing 1s infinite;
				&:before {
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					background-color: lightgray;
					filter: blur(15px);
					z-index: -1;
				}
				path {
					fill: var(--primary-theme);
				}
			}
			&__darkModehover {
				transform: scale(1.2);
				animation: glowing 1s infinite;
				&:before {
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					background-color: var(--primary-theme);
					filter: blur(15px);
					z-index: -1;
				}
				path {
					fill: white;
				}
			}
			&__selected {
				path {
					fill: var(--primary-theme);
				}
				#Oval-2 {
					fill: var(--primary-theme);
				}
			}
		}
		&__text {
			font-size: 18px;
			margin-left: 0.2rem;
			.MuiTypography-body1 {
				font-size: 1rem;
				font-weight: inherit;
			}
		}
		&__textOverflow {
			text-overflow: ellipsis;
			overflow: hidden;
			width: calc(100% - 10px);
		}
		&__SubText {
			cursor: pointer;
			span {
				&:hover {
					color: var(--primary-theme);
				}
			}
			.MuiTypography-body1 {
				font-size: 15px;
				font-weight: inherit;
				margin-right: 10px;
			}
		}
	}
	&__subItem {
		position: relative;
		margin: 0;
		.Mui-selected {
			background-color: none;
		}
		display: flex;
		width: 100%;
		justify-content: center;
		box-sizing: content-box;
		padding: 5px 25px !important;
	}
	&__subCategoryList,
	&__subCategoryListDark {
		position: absolute;
		margin-top: 5px;
		min-width: fit-content;
		border-radius: 10px;
		position: fixed;
		display: flex;
		align-items: center;
		overflow-y: scroll !important;
		transition-duration: 300ms;
		box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
		.MuiCollapse-wrapper {
			max-height: 70vh;
			min-height: 0px;
		}
	}
	&__subCategoryListClosed,
	&__subCategoryListClosedDark {
		min-height: 0px !important;
		max-height: 450px !important;
		overflow-y: scroll !important;
		transition-duration: 300ms;
		.MuiCollapse-wrapper {
			max-height: 450px;
			min-height: 0px;
		}
	}
}

.Sidebar__subCategoryList .MuiList-padding,
.Sidebar__subCategoryListDark .MuiList-padding {
	padding: 0.5rem 0rem 0.5rem !important;
	margin-left: 10px;
	margin-right: 10px;
}

.Sidebar__drawer .MuiDrawer-paper {
	margin-left: 0.1rem;
	border-radius: 6px !important;
	visibility: inherit !important;
	transform: none !important;
}

.Sidebar__list .MuiListItemIcon-root,
.Sidebar__listDark .MuiListItemIcon-root {
	min-width: 1.5rem;
	min-height: 1.5rem;
	border-radius: 6px;
	margin-right: 10px !important;
	display: flex;
	justify-content: center;
	align-items: center;
}

.Sidebar__listClosed .MuiListItemIcon-root,
.Sidebar__listClosedDark .MuiListItemIcon-root {
	min-height: 1.5rem;
	border-radius: 6px;
	margin-right: 10px !important;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.Sidebar__listDark .Sidebar__subCategoryList,
.Sidebar__list .Sidebar__subCategoryList {
	left: 236px;
}

.Sidebar__listClosedDark .Sidebar__subCategoryList,
.Sidebar__listClosed .Sidebar__subCategoryList {
	left: 80px;
}

.Sidebar__selected .Mui-selected {
	color: var(--subheading);
}

// !here
.Sidebar__list .Mui-selected,
.Sidebar__listClosed .Mui-selected {
	background-color: white;
}

.Sidebar .MuiListItem-root:hover {
	.Sidebar__item__text .MuiTypography-body1 {
		color: var(--primary-theme);
	}
	opacity: 0.9;
}

.Sidebar .MuiListItem- // !here
.Sidebar__subCategoryList .Mui-selected,
.Sidebar__subCategoryListClosed .Mui-selected {
	color: var(--sidebar-theme) !important;
	background-color: transparent !important;
}

.Sidebar__subCategoryListDark .Mui-selected,
.Sidebar__subCategoryListClosedDark .Mui-selected {
	color: var(--sidebar-theme) !important;
	background-color: transparent !important;
}

.Sidebar {
	&__drawer_open {
		overflow-x: hidden;
		transition: width 0.5s;
	}
	&__drawer_close {
		width: 80px !important;
		overflow-x: hidden;
		transition: all 1.5s ease-out;
		transition-timing-function: ease;
	}
}

// =============================
@media screen and (max-width: 1372px) {
	.Sidebar {
		&__item {
			padding: 0.3rem 1rem !important;
			padding-right: 28px !important;
			padding-left: 1rem !important;
			&__text {
				margin-top: 3px;
				margin-bottom: 3px;
			}
			svg {
				width: 21px;
				height: 21px;
			}
		}
		&__subItem {
			svg {
				width: 19px;
				height: 19px;
			}
		}
		&__subCategoryList {
			min-width: 16rem;
			left: 228px;
		}
		&__subCategoryListDark {
			min-width: 16rem;
			left: 228px;
		}
		&__subCategoryListClosed {
			min-width: 16rem;
			left: 86px;
		}
		&__subCategoryListClosedDark {
			min-width: 16rem;
			left: 86px;
		}
		&__header {
			height: 138px !important;
		}
		&__appbar {
			.MuiToolbar-root {
				padding-left: 0.5rem !important;
				padding-right: 0.5rem !important;
			}
		}
	}
}

@media screen and (min-width: 1372px) {
	.Sidebar {
		&__logoContainer,
		&__logoContainerClosed {
			height: 4.5rem;
		}
	}
}

.Sidebar__listClosed,
.Sidebar__listClosedDark {
	ul {
		.Sidebar__item {
			margin-left: 0rem !important;
			padding-left: 0rem !important;
			padding-right: 0rem !important;
		}
		.Sidebar__subItem {
			.sidebar_svg {
				min-width: 1.5rem;
			}
		}
	}
}

.Sidebar-backdrop {
	background-color: rgba(255, 255, 255, 0.4);
	-webkit-backdrop-filter: blur(5px);
	backdrop-filter: blur(5px);
}
